import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledSection = styled.section`
  padding: 60px 0;
  border-bottom-width: 0;

  ${breakpoint.medium`
    padding: 124px 0;
  `}

  .title {
    max-width: 280px;
    text-align: center;
    margin: 0 auto 30px auto;

    ${breakpoint.small`
      max-width: 100%;
      width: 100%;
    `}

    ${breakpoint.medium`
      margin: 0 0 40px 0;
    `}
  }

  .content {
    width: 100%;
    display: ${props => (props.contentIsVisible ? "flex" : "none")};
    flex-wrap: wrap;
    justify-content: space-between;

    &.visible {
      display: flex;
    }

    p {
      width: 100%;
      margin-bottom: 32px;
    }

		a {
			color: ${colors.orange};

			&:hover {
			  color: ${colors.purpleDark};
			}
		}	

  }

`

const Accessibility = () => {
  return (
    <StyledSection>
      <Container>
      <div className="content visible">
        <p>Visby Medical is committed to providing an accessible web experience. If you have difficulty accessing 
          our website content or notice any accessibility issues, please contact Visby at <a href="tel:+1833-468-4729">1-833-468-4729</a> or email us 
          at <a href="mailto:support@visby.com">support@visby.com</a>. Visby will be happy to work with you to enable better accessibility.
          </p>
          <p><a href="https://www.cigna.com/legal/compliance/machine-readable-files" target="_blank" rel="noopener noreferrer">Machine Readable Files</a><br />
          The above link leads to the machine-readable files that are made available in response to the federal Transparency in Coverage Rule and includes negotiated service rates and out-of-network allowed amounts between health plans and healthcare providers. The machine readable files are formatted to allow researchers, regulators, and application developers to more easily access and analyze data.</p>
          <p>Visby Medical welcomes your suggestions and comments about improving ongoing efforts to increase the accessibility of this website.</p>
        </div>
      </Container>
    </StyledSection>
  )
}
export default Accessibility
